import React from 'react';
import { Logo } from '../../assets/images';
import classes from './BABVLogo.module.css';

const BABVLogo = () => {
  return (
    <div className={classes.BABVLogo}>
      <img src={Logo} className={classes['BABVLogo-image']} alt="BABV Logo" />
      <h1 className={classes['BABVLogo-title']}>BABV</h1>
    </div>
  );
}

export default BABVLogo;