import React from 'react';

import classes from './BABVSocialItem.module.css';

const BABVSocialItem = (props) => {
  return (  
    <a className={classes.BABVSocialItem} href={props.url} target="_blank" rel="noopener noreferrer">
      <img className={classes['BABVSocialItem-image']} src={props.image} alt={props.alt} />
    </a>
  );
}
 
export default BABVSocialItem;