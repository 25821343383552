import React from 'react';

import BABVLogo from './components/BABVLogo/BABVLogo';
import BABVSocial from './components/BABVSocial/BABVSocial';
import BABVContactInfo from './components/BABVContactInfo/BABVContactInfo';
import BABVNav from './components/BABVNav/BABVNav';

import classes from './App.module.css';

const App = () => {
  return (
    <div className={classes.App}>
      <BABVLogo />
      <BABVSocial />
      <BABVContactInfo />
      <BABVNav />
    </div>
  );
}

export default App;
