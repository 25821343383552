import React from 'react';

import classes from './BABVContactInfo.module.css';

const BABVContactInfo = () => {
  return (  
    <div className={classes.BABVContactInfo}>
      <ul className={classes['BABVContactInfo--list']}>
        <li className={classes['BABVContactInfo--list-item']}>
          <p className={classes.Name}>HEILYN MOREIRA GARITA</p>
          <p>ARQUITECTA</p>
          <a href="mailto:heylin@babv.com">E | heilyn@babv.cr</a>
          <a className={classes.Cel} href="tel:+506-8741-2018">T | +506 8741 2018</a>
        </li>
        <li className={classes['BABVContactInfo--list-item']}>
          <p className={classes.Name}>BRYAN JIMENEZ MONTOYA</p>
          <p>ARQUITECTO | INGENIERO TOPOGRAFO</p>
          <a href="mailto:bryan@babv.com">E | bryan@babv.cr</a>
          <a className={classes.Cel} href="tel:+506-8670-2102">T | +506 8670 2102</a>
        </li>
      </ul>
      <p className={classes['BABVContactInfo--address']}>Los Yoses, San José, Costa Rica</p>
    </div>
  );
}
 
export default BABVContactInfo;