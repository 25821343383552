import React from 'react';

import classes from './BABVSocial.module.css';
import BABVSocialItem  from './BABVSocialItem/BABVSocialItem';
import { Facebook, Instagram, LinkedIn } from '../../assets/images';


const BABVSocial = () => {
  return (  
    <div className={classes.BABVSocial}>
      <BABVSocialItem
        url="https://www.facebook.com/babv.cr/"
        image={Facebook} 
        alt="Facebook BABV"/>
      <BABVSocialItem
        url="https://www.instagram.com/babv.cr/"
        image={Instagram}
        alt="Instagram BABV"/>
      <BABVSocialItem
        url="https://linkedin.com/company/babvcr/"
        image={LinkedIn}
        alt="LinkedIn BABV"/> 
    </div>
  );
}
 
export default BABVSocial;