import React from 'react';

import classes from './BABVNav.module.css';

const BABVNav = (props) => {
  return (  
    <div className={classes.BABVNav}>
      <ul className={classes['BABVNav--list']}>
        <li className={`${classes['BABVNav--list-item']} ${classes['Arqui']}`}>
          <a href='/'>ARQUITECTURA</a>
        </li>
        <li className={`${classes['BABVNav--list-item']}  ${classes['Topo']}`}>
          <a href='/'>TOPOGRAFIA</a>
        </li>
        <li className={`${classes['BABVNav--list-item']}  ${classes['Diseno']}`}>
          <a href='/'>DISEÑO GRAFICO</a>
        </li>
        <li className={`${classes['BABVNav--list-item']}  ${classes['Ilustr']}`}>
          <a href='/'>ILUSTRACION</a>
        </li>
      </ul>
      <div className={classes['BABVNav--footer']}></div>
    </div>
  );
}
 
export default BABVNav;